<template>
  <div class="main">
    <!-- <h3><span>忘记密码</span></h3> -->
    <a-form ref="formRegister" :form="form" id="formRegister">
      <a-form-item>
        <div class="allInputBox">
          <a-input
            size="large"
            type="text"
            placeholder="请输入邮箱"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: $t('user.email.required'),
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                  }
                ],
                validateTrigger: ['change', 'blur']
              }
            ]"
          >
          </a-input>
        </div>
      </a-form-item>
      <a-form-item>
        <div class="allInputBox">
          <a-input-password
            size="large"
            @click="handlePasswordInputClick"
            placeholder="请输入密码"
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入密码' }, { validator: this.handlePasswordLevel }],
                validateTrigger: ['change', 'blur']
              }
            ]"
          >
          </a-input-password>
        </div>
      </a-form-item>
      <a-form-item>
        <div class="allInputBox">
          <a-input-password
            size="large"
            placeholder="请输入确认密码"
            v-decorator="[
              'confirmPassword',
              {
                rules: [{ required: true, message: '请输入确认密码' }, { validator: this.handlePasswordCheck }],
                validateTrigger: ['change', 'blur']
              }
            ]"
          >
          </a-input-password>
        </div>
      </a-form-item>
      <a-form-model-item style="text-align: left">
        <div class="allInputBox">
          <div style="display: flex">
            <a-input
              size="large"
              placeholder="请输入验证码"
              v-decorator="[
                'code',
                { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: ['change', 'blur'] }
              ]"
            />
            <a-button
              size="large"
              style="margin-left: 10px; height: 45px; border-radius: 12px"
              type="primary"
              :disabled="time !== 60"
              @click="handleCode"
            >{{ time == 60 ? '获取验证码' : `${time}s后再次获取` }}</a-button
            >
          </div>
        </div>
      </a-form-model-item>
      <a-form-item style="padding-bottom: 6vh">
        <div class="btn-box">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="register-button"
            :loading="registerBtn"
            @click.stop.prevent="handleSubmit"
            :disabled="registerBtn"
          >{{ '确认修改' }}
          </a-button>
          <router-link class="login" :to="{ name: 'login' }">去登录</router-link>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { scorePassword } from '@/utils/util'
import { verifyCode, resetPassword } from '@/api/system'
import { Encrypt } from '@/utils/jsencrypt'
export default {
  data () {
    return {
      form: this.$form.createForm(this),
      registerBtn: false,
      time: 60,
      state: {
        time: 60,
        level: 0,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: '#999999'
      },
      code: undefined
    }
  },
  computed: {},
  methods: {
    handlePasswordLevel (rule, value, callback) {
      if (!value) {
        return callback()
      }
      // const str = /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8,}$/
      // const reg = new RegExp(str)
      if (value.length >= 8) {
      // if (value.length >= 8 && reg.test(value)) {
        if (scorePassword(value) >= 30) {
          this.state.level = 1
        }
        if (scorePassword(value) >= 60) {
          this.state.level = 2
        }
        if (scorePassword(value) >= 80) {
          this.state.level = 3
        }
      } else {
        this.state.level = 0
        // callback(new Error('密码不低于8位，包含大小写字母、数字特殊符号'))
        callback(new Error('密码不能低于8位'))
      }
      this.state.passwordLevel = this.state.level
      this.state.percent = this.state.level * 33
      callback()
    },
    handlePasswordCheck (rule, value, callback) {
      const password = this.form.getFieldValue('password')
      if (value === undefined) {
        callback(new Error(this.$t('user.password.required')))
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('密码与确认密码不一致'))
      }
      callback()
    },
    handlePasswordInputClick () {
      if (!this.isMobile) {
        this.state.passwordLevelChecked = true
        return
      }
      this.state.passwordLevelChecked = false
    },
    // 获取验证码
    async verifyCodeFn (data) {
      const res = await verifyCode(data)
      if (res.code === 200) {
        this.timeId = setInterval(() => {
          this.time--
          if (this.time <= 0) {
            this.time = 60
            clearInterval(this.timeId)
            return false
          }
        }, 1000)
        this.form.setFieldsValue({ code: res.data })
      }
    },
    // 修改密码
    async resetPasswordFn (data) {
      const res = await resetPassword(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        setTimeout(() => {
          this.$router.push('/user/login')
        }, 500)
      }
      this.registerBtn = false
    },
    // 发送验证码
    handleCode () {
      // const email = this.form.getFieldValue('email')
      // if(!email) return
      const {
        form: { validateFields }
      } = this
      validateFields({ force: true }, (err, values) => {
        if (err.email || err.password || err.confirmPassword) return
        const { email } = values
        if (email) {
          this.verifyCodeFn(email)
        }
      })
    },
    // 提交
    handleSubmit () {
      const {
        form: { validateFields },
        state
      } = this
      validateFields({ force: true }, (err, values) => {
        if (!err) {
          const { password, confirmPassword } = values
          if (password !== confirmPassword) {
            return this.$message.error('密码与确认密码不一致')
          }
          state.passwordLevelChecked = false
          this.registerBtn = true
          this.resetPasswordFn({
            ...values,
            password: Encrypt(values.password),
            confirmPassword: Encrypt(values.confirmPassword)
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep {
  .allInputBox {
    input {
      background: #fafafa;
      border-radius: 12px;
      outline: 1px solid #eee;
      border: none;
      padding: 25px;
      margin-bottom: 10px;
      color: #999999;
    }
    input:-webkit-autofill,
    input[type='password'] {
      /* 字体颜色，需要设置，否则会变成黑色 */
      // -webkit-text-fill-color: #333 !important;
      /* 变成透明背景色的固定写法，只针对表单自动填充的背景色 */
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  .ant-form-explain {
    margin-left: 10px;
  }
}
  .btn-box{
    display: flex;
    justify-content: space-between;
    @media (max-width: 998px) {
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
    .register-button {
      height: 45px;
      border-radius: 12px;
    }
    .login{
      @media (max-width: 998px) {
        padding-top: 5%;
      }
      text-align: center;
    }
  }
</style>
